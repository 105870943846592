import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Pic1 from '../images/sol (1).jpg'
import Pic2 from '../images/sol (2).jpg'
import Pic3 from '../images/sol (3).jpg'
import Pic4 from '../images/sol (4).jpg'
import Pic5 from '../images/sol (5).jpg'
import Pic6 from '../images/sol (6).jpg'
import Pic7 from '../images/sol (7).jpg'
import Pic8 from '../images/sol (8).jpg'
import Pic9 from '../images/sol (9).jpg'
import Pic10 from '../images/sol (10).jpg'
import Pic11 from '../images/sol (11).jpg'
import Pic12 from '../images/sol (12).jpg'
import Pic13 from '../images/sol (13).jpg'
import Pic14 from '../images/sol (14).jpg'
import Pic15 from '../images/sol (15).jpg'
import Pic16 from '../images/sol (16).jpg'
import Pic17 from '../images/sol (17).jpg'
import Pic18 from '../images/sol (18).jpg'
import Pic19 from '../images/sol (19).jpg'
import Pic20 from '../images/sol (20).jpg'
import Pic21 from '../images/sol (21).jpg'
import Pic22 from '../images/sol (22).jpg'
import Pic23 from '../images/sol (23).jpg'
import Pic24 from '../images/sol (24).jpg'
import Pic25 from '../images/sol (25).jpg'
import Pic26 from '../images/sol (26).jpg'
import Pic27 from '../images/sol (27).jpg'
import Pic28 from '../images/sol (28).jpg'
import Pic29 from '../images/sol (29).jpg'
import Pic30 from '../images/sol (30).jpg'
import Pic31 from '../images/sol (31).jpg'
import Pic32 from '../images/sol (32).jpg'
import Pic33 from '../images/sol (33).jpg'
import Pic34 from '../images/sol (34).jpg'
import Pic35 from '../images/sol (35).jpg'
import Pic36 from '../images/sol (36).jpg'
import Pic37 from '../images/sol (37).jpg'
import Pic38 from '../images/sol (38).jpg'
import Pic39 from '../images/sol (39).jpg'
import Pic40 from '../images/sol (40).jpg'
import Pic41 from '../images/sol (41).jpg'
import Pic42 from '../images/sol (42).jpg'
import Pic43 from '../images/sol (43).jpg'
import Pic44 from '../images/sol (44).jpg'
import Pic45 from '../images/sol (45).jpg'
import Pic46 from '../images/sol (46).jpg'
import Pic47 from '../images/sol (47).jpg'
import Pic48 from '../images/sol (48).jpg'
import Pic49 from '../images/sol (49).jpg'
import Pic50 from '../images/sol (50).jpg'
import Pic51 from '../images/sol (51).jpg'
import Pic52 from '../images/sol (52).jpg'
import Pic53 from '../images/sol (53).jpg'
import Pic54 from '../images/sol (54).jpg'
import Pic55 from '../images/sol (55).jpg'
import Pic56 from '../images/sol (56).jpg'
import Pic57 from '../images/sol (57).jpg'
import Pic58 from '../images/sol (58).jpg'
import Pic59 from '../images/sol (59).jpg'
import Pic60 from '../images/sol (60).jpg'
import Pic61 from '../images/sol (61).jpg'
import Pic62 from '../images/sol (62).jpg'
import Pic63 from '../images/sol (63).jpg'
import Pic64 from '../images/sol (64).jpg'
import Pic65 from '../images/sol (65).jpg'
import Pic66 from '../images/sol (66).jpg'
import Pic67 from '../images/sol (67).jpg'
import Pic68 from '../images/sol (68).jpg'
import Pic69 from '../images/sol (69).jpg'
import Pic70 from '../images/sol (70).jpg'


import '../styles/grid.css'
const SecondPage = () => (
  <Layout>
    <Seo title="Our Students" />
    <section className="picture-group">
      <h1 className="students-title">Our Students</h1>




      <div class="pic-row">
        <div class="pic-column">
          <img src={Pic1} style={{ width: '100%' }} />
          <img src={Pic2} style={{ width: '100%' }} />
          <img src={Pic3} style={{ width: '100%' }} />
          <img src={Pic4} style={{ width: '100%' }} />
          <img src={Pic5} style={{ width: '100%' }} />
          <img src={Pic6} style={{ width: '100%' }} />
          <img src={Pic7} style={{ width: '100%' }} />
          <img src={Pic8} style={{ width: '100%' }} />
          <img src={Pic9} style={{ width: '100%' }} />
          <img src={Pic10} style={{ width: '100%' }} />
          <img src={Pic11} style={{ width: '100%' }} />
          <img src={Pic12} style={{ width: '100%' }} />
          <img src={Pic13} style={{ width: '100%' }} />
          <img src={Pic14} style={{ width: '100%' }} />
          <img src={Pic15} style={{ width: '100%' }} />
          <img src={Pic16} style={{ width: '100%' }} />
          <img src={Pic17} style={{ width: '100%' }} />
          <img src={Pic18} style={{ width: '100%' }} />
          <img src={Pic19} style={{ width: '100%' }} />
          <img src={Pic58} style={{ width: '100%' }} />
          <img src={Pic59} style={{ width: '100%' }} />
          <img src={Pic60} style={{ width: '100%' }} />

        </div>

        <div class="pic-column">
          <img src={Pic39} style={{ width: '100%' }} />
          <img src={Pic40} style={{ width: '100%' }} />
          <img src={Pic41} style={{ width: '100%' }} />
          <img src={Pic42} style={{ width: '100%' }} />
          <img src={Pic43} style={{ width: '100%' }} />
          <img src={Pic44} style={{ width: '100%' }} />
          <img src={Pic45} style={{ width: '100%' }} />
          <img src={Pic46} style={{ width: '100%' }} />
          <img src={Pic47} style={{ width: '100%' }} />
          <img src={Pic48} style={{ width: '100%' }} />
          <img src={Pic49} style={{ width: '100%' }} />
          <img src={Pic50} style={{ width: '100%' }} />
          <img src={Pic51} style={{ width: '100%' }} />
          <img src={Pic52} style={{ width: '100%' }} />
          <img src={Pic38} style={{ width: '100%' }} />
        </div>
        <div class="pic-column">
          <img src={Pic53} style={{ width: '100%' }} />
          <img src={Pic54} style={{ width: '100%' }} />
          <img src={Pic55} style={{ width: '100%' }} />
          <img src={Pic56} style={{ width: '100%' }} />
          <img src={Pic57} style={{ width: '100%' }} />

          <img src={Pic64} style={{ width: '100%' }} />
          <img src={Pic65} style={{ width: '100%' }} />
          <img src={Pic66} style={{ width: '100%' }} />
          <img src={Pic67} style={{ width: '100%' }} />
          <img src={Pic68} style={{ width: '100%' }} />
          <img src={Pic69} style={{ width: '100%' }} />
          <img src={Pic70} style={{ width: '100%' }} />
          <img src={Pic36} style={{ width: '100%' }} />
        </div>
        <div class="pic-column">
          <img src={Pic62} style={{ width: '100%' }} />
          <img src={Pic63} style={{ width: '100%' }} />
          <img src={Pic20} style={{ width: '100%' }} />
          <img src={Pic21} style={{ width: '100%' }} />
          <img src={Pic22} style={{ width: '100%' }} />
          <img src={Pic23} style={{ width: '100%' }} />
          <img src={Pic24} style={{ width: '100%' }} />
          <img src={Pic25} style={{ width: '100%' }} />
          <img src={Pic26} style={{ width: '100%' }} />
          <img src={Pic27} style={{ width: '100%' }} />
          <img src={Pic28} style={{ width: '100%' }} />
          <img src={Pic29} style={{ width: '100%' }} />
          <img src={Pic30} style={{ width: '100%' }} />
          <img src={Pic31} style={{ width: '100%' }} />
          <img src={Pic32} style={{ width: '100%' }} />
          <img src={Pic33} style={{ width: '100%' }} />
          <img src={Pic34} style={{ width: '100%' }} />
          <img src={Pic35} style={{ width: '100%' }} />
          <img src={Pic61} style={{ width: '100%' }} />
          <img src={Pic37} style={{ width: '100%' }} />
        </div>


      </div>



    </section>
  </Layout >
)

export default SecondPage
